import UtilityService from '../services/utility.service'
import PropertyModel from './PropertyModel'
import _ from 'lodash'

export default class ProductModel {
  constructor () {
    this.id = 0
    this.name = ''
    this.name_bn = ''
    this.feature_photos = ''
    this.feature_photo_files = []
    this.thumbnail_files = []
    this.thumbnails = ''
    this.overview = ''
    this.overview_bn = ''
    this.feature = ''
    this.feature_bn = ''
    this.properties = ''
    this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
  }

  load (data) {
    this.id = data.id
    this.name = data.name
    this.name_bn = data.name_bn
    this.overview = data.overview
    this.overview_bn = data.overview_bn
    this.feature_photos = data.feature_photos ?? ''
    this.feature_photo_files = data.feature_photo_files ?? []
    this.thumbnails = data.thumbnails ?? ''
    this.thumbnail_files = data.thumbnail_files ?? []
    this.feature = data.feature
    this.feature_bn = data.feature_bn
    this.properties = _.map(data.properties, function (p) {
      return new PropertyModel().load(p)
    })
    this.orderInterDependentProperties()
    this.created_at = UtilityService.formatDateTime(data.created_at, 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(data.updated_at, 'MM/DD/YY hh:mm A')
    return this
  }

  orderInterDependentProperties () {
    this.properties = _.sortBy(this.properties, function (p) {
      return p.order_index
    })
  }

  fetchPropertyByTitle (title, properties) {
    if (!properties) {
      properties = this.properties
    }
    const vm = this
    const targetProperty = new PropertyModel()
    _.forEach(properties, function (p) {
      if (p.title === title) {
        targetProperty.load(p)
        return targetProperty
      } else {
        const targetChild = vm.fetchChildPropertyByPropertyName(p, title)
        if (targetChild) {
          targetProperty.load(targetChild)
          return targetProperty
        }
      }
    })
    return targetProperty
  }

  fetchChildPropertyByPropertyName (ParentProperty, PropertyName) {
    if (!ParentProperty || !ParentProperty.childs) {
      return ''
    }
    return _.find(ParentProperty.childs, function (property) {
      return property.title === PropertyName
    })
  }
}
