import Config from './config'
var api = Config.api

const productAPIRoot = api.BASE_URL + '/product'

class ProductService {
  createProduct (ProductModel) {
    return api.postCall(productAPIRoot, ProductModel, api.ShowAlert, api.ShowAlert)
  }

  createProductInBulk (ProductModel) {
    return api.postCall(productAPIRoot + '/bulk-create', ProductModel, api.ShowAlert, api.ShowAlert)
  }

  getProducts () {
    return api.getCall(productAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getProductsListWithBasicInfo (pageNumber) {
    return api.getCall(productAPIRoot + '/basic-list', api.DoNothing, api.ShowAlert)
  }

  getProductById (ProductId) {
    return api.getCall(productAPIRoot + '/' + ProductId, api.DoNothing, api.ShowAlert)
  }

  updateProduct (ProductModel) {
    return api.putCall(productAPIRoot + '/' + ProductModel.id, ProductModel, api.ShowAlert, api.ShowAlert)
  }

  deleteProduct (ProductId) {
    return api.deleteCall(productAPIRoot + '/' + ProductId, api.ShowAlert, api.ShowAlert)
  }
}
export default new ProductService()
