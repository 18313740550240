import Config from './config'
var api = Config.api

const propertyAPIRoot = api.BASE_URL + '/property'

class PropertyService {
  createProperty (PropertyModel) {
    return api.postCall(propertyAPIRoot, PropertyModel, api.ShowAlert, api.ShowAlert)
  }

  getProperties () {
    return api.getCall(propertyAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getPropertyById (PropertyId) {
    return api.getCall(propertyAPIRoot + '/' + PropertyId, api.DoNothing, api.ShowAlert)
  }

  updateProperty (PropertyModel) {
    return api.putCall(propertyAPIRoot + '/' + PropertyModel.id, PropertyModel, api.ShowAlert, api.ShowAlert)
  }

  deleteProperty (PropertyId) {
    return api.deleteCall(propertyAPIRoot + '/' + PropertyId, api.ShowAlert, api.ShowAlert)
  }
}
export default new PropertyService()
