import Config from './config'
var api = Config.api

const specificationAPIRoot = api.BASE_URL + '/specification'

class SpecificationService {
  createSpecification (SpecificationModel) {
    return api.postCall(specificationAPIRoot, SpecificationModel, api.ShowAlert, api.ShowAlert)
  }

  getSpecifications () {
    return api.getCall(specificationAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getSpecificationById (SpecificationId) {
    return api.getCall(specificationAPIRoot + '/' + SpecificationId, api.DoNothing, api.ShowAlert)
  }

  updateSpecification (SpecificationModel) {
    return api.putCall(specificationAPIRoot + '/' + SpecificationModel.id, SpecificationModel, api.ShowAlert, api.ShowAlert)
  }

  deleteSpecification (SpecificationId) {
    return api.deleteCall(specificationAPIRoot + '/' + SpecificationId, api.ShowAlert, api.ShowAlert)
  }
}
export default new SpecificationService()
