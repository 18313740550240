<template>
  <div class="page-heading">
    <div class="page-title">
      <div class="row">
        <div class="col-12 col-md-6 order-md-1 order-last">
          <h3>All Specifications</h3>
          <p class="text-subtitle text-muted">View your added specifications and add new ones</p>
        </div>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav aria-label="breadcrumb" class="breadcrumb-header float-start float-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }" >Dashboard</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">All Product Specifications</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <!-- // Basic multiple Column Form section start -->
    <section id="multiple-column-form">
      <div class="row match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <button @click="openAddForm=!openAddForm" class="card-title btn btn-outline-dark">Add Specification</button>
            </div>
            <div v-show="openAddForm" class="card-content">
              <div class="card-body">
                <Form class="form" v-slot="{ handleSubmit }" @submit.prevent="createSpecification" :validation-schema="ValidationSchema">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Parent Property</label>
                        <Field as="select" :class="'form-control'" v-model="selectedParent_propertyId" name="parent_property">
                          <option selected disabled hidden value="0">Select Parent Property</option>
                          <option v-for="property in RootProperties" :key="property.id" :value="property.id">{{ property.title }}</option>
                        </Field>
                        <ErrorMessage class="text-danger" name="parent_propertyId"/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Property</label>
                        <Field as="select" :class="'form-control'" v-model="SpecificationModel.property_id" name="property"
                               placeholder="Specification description in bangla">
                          <option selected disabled hidden value="0">{{selectedParent_propertyId===0 ? 'Select Parent Property First' : 'Select Property'}}</option>
                          <option v-for="property in ChildProperties" :key="property.id"
                                  :value="property.id">{{ property.title }}
                          </option>
                        </Field>
                        <ErrorMessage class="text-danger" name="property"/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="spec-value">Specification Value</label>
                        <Field type="text" class="form-control" v-model="SpecificationModel.value" name="title" placeholder="Specification value"/>
                        <ErrorMessage class="text-danger" name="title" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Specification Value (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="SpecificationModel.value_bn" name="title_bn"
                               placeholder="Specification value in bangla"/>
                        <ErrorMessage class="text-danger" name="title_bn" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Product</label>
                        <Field as="select" :class="'form-control'" v-model="SpecificationModel.product_id" name="product"
                               placeholder="Specification description in bangla">
                          <option selected disabled hidden value="0">Select Product</option>
                          <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
                        </Field>
                        <ErrorMessage class="text-danger" name="product"/>
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <div class='form-check'>
                        <div class="checkbox">
                          <input type="checkbox" id="checkbox5" class='form-check-input' checked>
                          <label for="checkbox5">Flag Summary</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                      <button class="btn btn-primary me-1 mb-1" v-show="!SpecificationModel.id" @click="handleSubmit($event, createSpecification)">Create</button>
                      <button class="btn btn-primary me-1 mb-1" v-show="SpecificationModel.id" @click="handleSubmit($event, updateSpecification)">Update</button>
                      <button class="btn btn-secondary me-1 mb-1" @click="reset">Reset</button>
                      <button class="btn btn-danger me-1 mb-1" @click="openAddForm=false">Cancel</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          <button class="badge bg-success" @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class="card-body">
          <table class="table table-striped" id="table1">
            <thead>
            <tr>
              <th>#</th>
              <th>Value</th>
              <th>Value BN</th>
              <th>product_id</th>
              <th>property_id</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="specification in specifications" :key="specification.id" >
              <td>{{ specification.id }}</td>
              <td>{{ specification.value }}</td>
              <td>{{ specification.value_bn }}</td>
              <td>{{ specification.product_id }}</td>
              <td>{{ specification.property_id }}</td>
              <td>
                <span class="badge bg-light-success">Active</span>
              </td>
              <td>
                <button class="badge bg-danger mr-5" @click="deleteSpecification(specification.id)">Delete</button>
                <button class="badge bg-primary ml-5" @click="editSpecification(specification)">Edit</button>
              </td>
            </tr>
            <tr v-if="!specifications.length">
              <td colspan="7" class="text-center">No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<script>

import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import SpecificationService from '../../services/specification.service'
import ValidationService from '../../services/validation.service'
import SpecificationModel from '../../models/SpecificationModel'
import PropertyService from '../../services/property.service'
import ProductService from '../../services/product.service'
import ProductModel from '../../models/ProductModel'
import PropertyModel from '../../models/PropertyModel'

export default {
  name: 'Specification',
  data () {
    return {
      ValidationSchema: ValidationService.createUpdateSpecification,
      specifications: [],
      SpecificationModel: new SpecificationModel(0, 0),
      properties: [],
      products: [],
      openAddForm: false,
      selectedParent_propertyId: 0
    }
  },
  components: { Form, Field, ErrorMessage },
  mounted () {
    this.getSpecifications()
    this.getProperties()
    this.getProducts()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getProperties () {
      this.properties = []
      PropertyService.getProperties().then(
        response => {
          if (response.data.status) {
            this.properties = _.map(response.data.objects.properties, function (p) {
              return new PropertyModel().load(p)
            })
          }
        }
      )
    },
    getProducts () {
      this.products = []
      ProductService.getProducts().then(
        response => {
          if (response.data.status) {
            this.products = _.map(response.data.objects.products, function (p) {
              return new ProductModel().load(p)
            })
          }
        }
      )
    },
    getSpecifications () {
      this.specifications = []
      SpecificationService.getSpecifications().then(
        response => {
          if (response.data.status) {
            this.specifications = _.map(response.data.objects.specifications, function (p) {
              return new SpecificationModel(0, 0).load(p)
            })
          }
        }
      )
    },
    createSpecification () {
      const vm = this
      SpecificationService.createSpecification(this.SpecificationModel).then(
        response => {
          if (response.data.status) {
            vm.specifications.push(new SpecificationModel(vm.SpecificationModel.product_id, vm.SpecificationModel.property_id).load(response.data.objects.specification))
            vm.reset()
          }
        }
      )
    },
    deleteSpecification (SpecificationId) {
      const vm = this
      SpecificationService.deleteSpecification(SpecificationId).then(
        response => {
          if (response.data.status) {
            vm.specifications = _.filter(vm.specifications, function (p) { return p.id !== SpecificationId })
          }
        }
      )
    },
    editSpecification (SpecificationModel) {
      this.SpecificationModel = SpecificationModel
      this.openAddForm = true
    },
    updateSpecification () {
      const vm = this
      SpecificationService.updateSpecification(this.SpecificationModel).then(
        response => {
          if (response.data.status) {
            const specification = _.find(vm.specifications, function (p) { return p.id === vm.SpecificationModel.id })
            specification.load(response.data.objects.specification)
          }
        }
      )
    },
    reset () {
      this.SpecificationModel = new SpecificationModel(0, 0)
    }
  },
  computed: {
    RootProperties () {
      return _.filter(this.properties, function (p) {
        return p.parent_propertyId === 0
      })
    },
    ChildProperties () {
      const vm = this
      const property = _.find(this.properties, function (p) {
        return p.id === vm.selectedParent_propertyId
      })
      return property ? property.childs : []
    }
  }
}
</script>
