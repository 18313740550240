import UtilityService from '../services/utility.service'
import SpecificationModel from './SpecificationModel'
import _ from 'lodash'

export default class PropertyModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.title_bn = ''
    this.description = ''
    this.description_bn = ''
    this.isSummary = 1
    this.parent_propertyId = 0
    this.specification = new SpecificationModel(0, 0)
    this.childs = []
    this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.order_index = 0.0
  }

  load (data, ProductId) {
    this.id = data.id
    this.title = data.title
    this.title_bn = data.title_bn
    this.description = data.description
    this.description_bn = data.description_bn
    this.isSummary = data.isSummary
    this.order_index = data.order_index ? parseFloat(data.order_index) : 0.0
    if (data.specification) {
      this.specification = new SpecificationModel(ProductId, data.id).load(data.specification)
    } else {
      this.specification = new SpecificationModel(ProductId, data.id)
    }
    if (data.childs) {
      this.childs = _.map(data.childs, function (c) {
        return new PropertyModel().load(c)
      })
      this.orderInterDependentChildProperties()
    }
    this.parent_propertyId = data.parent_propertyId
    this.created_at = UtilityService.formatDateTime(data.created_at, 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(data.updated_at, 'MM/DD/YY hh:mm A')
    return this
  }

  orderInterDependentChildProperties () {
    this.childs = _.sortBy(this.childs, function (p) {
      return p.order_index
    })
  }
}
