import UtilityService from '../services/utility.service'
export default class SpecificationModel {
  constructor (productId, PropertyId) {
    this.id = 0
    this.product_id = productId
    this.property_id = PropertyId
    this.value = ''
    this.value_bn = ''
    this.created_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
  }

  load (data) {
    this.id = data.id
    this.product_id = data.product_id
    this.property_id = data.property_id
    this.value = data.value
    this.value_bn = data.value_bn
    this.created_at = UtilityService.formatDateTime(data.created_at, 'MM/DD/YY hh:mm A')
    this.updated_at = UtilityService.formatDateTime(data.updated_at, 'MM/DD/YY hh:mm A')
    return this
  }
}
